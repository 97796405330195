.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #2f424f;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #62c8d5;
}

.result {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-text {
  text-align: left;
  font: monospace;
}

.App-header-button {
  flex: 1;
  justify-content: center;
  justify-items: center;
}

.App-header-desc {
  text-align: left;
  flex: 2;
  justify-content: center;
  justify-items: center;
}

.body-section {
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
}

.body-section-left {
  margin: 20px;
  flex: 1;
  justify-content: center;
  justify-items: center;
}

.body-section-right {
  margin: 20px;
  flex: 1;
  justify-content: center;
  justify-items: center;
}


.footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #2f424f;
  color: white;
  text-align: center;
}